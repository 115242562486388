/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import striptags from "striptags"
import { useLocation } from "@reach/router"
import LogoIco from "../images/logo.ico"
import LogoPNG from "../images/logo.png"
import gatsbyi18Context from "../contexts/gatsby-i18n-context"

function SEO(props) {
  const { description, lang, dir, meta, type = "website", title, image } = props
  const { language: gatsbyI18nLanguage } = useContext(gatsbyi18Context)

  const location = useLocation()
  const { t, i18n } = useTranslation()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            title_en
            description
            description_en
            author
          }
        }
      }
    `
  )

  const isEnglish =
    (gatsbyI18nLanguage && gatsbyI18nLanguage === "en") ||
    (i18n.language && i18n.language === "en") ||
    /\/en\/?$/.test(location.pathname)

  const defaultTitle = isEnglish
    ? site.siteMetadata["title_en"]
    : site.siteMetadata["title"]

  const defaultDescription = isEnglish
    ? site.siteMetadata["description_en"]
    : site.siteMetadata["description"]

  const metaDescription = striptags(description) || defaultDescription
  const ogTitle = `${defaultTitle} - ${title || defaultDescription}`

  return (
    <Helmet
      title={title || defaultDescription}
      titleTemplate={`${defaultTitle} - %s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: ogTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: type,
        },
        {
          property: `og:image`,
          content: "https://almadaq.net" + (image || LogoPNG),
        },
        {
          property: "og:locale",
          content: "ar_EG",
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: ogTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link rel="icon" type="image/png" href={LogoIco} sizes="16x16" />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `ar`,
  dir: "rtl",
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  dir: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  // title: PropTypes.string.isRequired,
}

export default SEO
