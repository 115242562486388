import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { useTranslation  } from 'react-i18next'
import MobileMessage from './mobile-message'
import Helmet from 'react-helmet'
import '../scss/index.scss'
import gatsbyI18Context from '../contexts/gatsby-i18n-context'

const Layout = ({ children, className='' }) => {
  const { language: gatsbyI18Language } = useContext(gatsbyI18Context)
  const { i18n } = useTranslation()
  const lang = gatsbyI18Language || i18n.language || 'ar'
  const dir = lang === 'ar' ? 'rtl' : 'ltr'

  if (gatsbyI18Language && i18n.language !== gatsbyI18Language) {
    i18n.changeLanguage(gatsbyI18Language)
  }

  return (
    <>
      <Helmet>
        <html lang={lang} dir={dir} />
      </Helmet>
      <ThemeProvider theme={createMuiTheme({ direction: dir })}>
        <MobileMessage>
          <main className={className}>{children}</main>
        </MobileMessage>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
