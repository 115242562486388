import React, { Component } from 'react'
import Logo from '../images/logo.svg'

export default class MobileMessege extends Component {
  constructor (props) {
    super(props)

    this.acknowledge = this.acknowledge.bind(this)
    this.state = {
      acknowledge: false
    }
  }

  componentDidMount () {
    if (window.localStorage.mobileMessageSeen) {
      this.setState({
        isAcknowledge: true
      })
    }
  }

  acknowledge () {
    window.localStorage.mobileMessageSeen = true

    this.setState({
      isAcknowledge: true
    })
  }

  render () {
    const {isAcknowledge} = this.state
    const {children} = this.props

    return (
      <>
        {
          !isAcknowledge ? (
            <div className="mobile-message d-block d-lg-none">
              <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <div className="message d-flex flex-column align-items-center">
                  <button onClick={this.acknowledge} className="btn btn-link">
                    <i className="x"></i>
                  </button>
                  <img className="logo my-4" src={Logo} alt="Almadaq" />
                  <p className="text-center text-muted my-0">
                    لأفضل حالات التصفح للموقع برجاء فتح الموقع من خلال الحاسوب الشخصي علي نسخة حديثة من متصفح كروم, سفاري أو فاير فوكس
                  </p>
                </div>
              </div>
            </div>
          ) : (null)
        }

        {children}
      </>
    )
  }
}
